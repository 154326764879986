.picture-box {
  height: calc(100px + 1em);
  margin-bottom: 1em;
  padding: 0.5em;
  background: rgb(86, 89, 93);
  border-radius: 5px;
}

.quote-box {
  margin-top: 1em;
  padding: 0.5em;
  border-radius: 5px;
}

.quote {
  font-style: italic;
}

.author {
  font-weight: bold;
  text-align: right;
}

.author::before {
  content: " - ";
}

#picture img {
  width: 100%;
  height: 100%;
}
